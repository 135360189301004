import { notification } from 'antd';

const ToastSuccess = message => {
    notification.success({
        message: message,
        placement: 'topRight',
        top: 70,
    });
};

const ToastWarning = message => {
    notification.warning({
        message: message,
        placement: 'topRight',
        top: 70,
    });
};

const ToastError = (message, duration = 3) => {
    notification.error({
        message: message,
        duration: duration,
        placement: 'topRight',
        top: 70,
    });
};

const ToastInfo = message => {
    notification.info({
        message: message,
        placement: 'topRight',
        top: 70,
    });
};

const ToastErrorWithDescription = (message, description) => {
    notification.error({
        message: message,
        description: description,
        placement: 'topRight',
        top: 70,
    });
};

export {
    ToastSuccess,
    ToastWarning,
    ToastError,
    ToastInfo,
    ToastErrorWithDescription,
};

