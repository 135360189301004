/**
 * The global state selectors
 */

import { createSelector } from 'reselect';
import { ApplicationRootState } from 'types';

const selectGlobal = (state: ApplicationRootState) => {
  return state.global;
};

const selectConfig = (state: ApplicationRootState) => {
  return state.config;
};

const selectJourney = (state: ApplicationRootState) => {
  return state.journey;
};

const selectRoute = (state: ApplicationRootState) => {
  return state.router;
};

const makeSelectConfigAppkey = () =>
  createSelector(selectConfig, configState => configState.appKey);

const makeSelectWynkMusicConfig = () =>
  createSelector(selectConfig, configState => configState.wynkMusic);

const makeSelectXstreamConfig = () =>
  createSelector(selectConfig, configState => configState.xstream);

const makeSelectCurrentUser = () =>
  createSelector(selectGlobal, globalState => globalState.currentUser);

const makeSelectLoading = () =>
  createSelector(selectGlobal, globalState => globalState.loading);

const makeSelectError = () =>
  createSelector(selectGlobal, globalState => globalState.error);

const makeSelectLocation = () =>
  createSelector(selectRoute, routeState => routeState.location);

const makeSelectUserData = () =>
  createSelector(selectGlobal, globalState => globalState.userData);

const makeSelectWindow = () =>
  createSelector(selectGlobal, globalState => globalState.closeWindow);

export {
  selectGlobal,
  selectConfig,
  selectJourney,
  makeSelectWynkMusicConfig,
  makeSelectXstreamConfig,
  makeSelectCurrentUser,
  makeSelectLoading,
  makeSelectError,
  makeSelectLocation,
  makeSelectUserData,
  makeSelectWindow,
  makeSelectConfigAppkey,
};
