import ActionTypes from './constants';
import { ContainerState, ContainerActions } from './types';

// The initial state of the App
export const initialState: ContainerState = {
  tempJourneyData: {
    journeyId: '',
    journeyName: '',
    journeyDesc: '',
    journeyUri: '',
    allowedRoles: [],
    allowedTenants: [],
  },
  currentStep: 0,
  tempCollection: {},
  journeyAction: 'CREATE',
  completed: false,
  journeyLoader: false,
};


// Take this container's state (as a slice of root state), this container's actions and return new state
function journeyReducer(
  state: ContainerState = initialState,
  action: ContainerActions,
): ContainerState {
  switch (action.type) {
    case ActionTypes.TEMP_JOURNEY_DATA_SUCCESS:
      return { ...state, tempJourneyData: action.payload };
    case ActionTypes.UPDATE_TEMP_COLLECTION:
      return { ...state, tempCollection: action.payload, journeyAction: 'CREATE' };
    case ActionTypes.UPDATE_TEMP_EDIT_COLLECTION:
      return { ...state, tempCollection: action.payload, journeyAction: 'EDIT' };
    case ActionTypes.UPDATE_CURRENT_STEP:
      return { ...state, currentStep: action.payload };
    case ActionTypes.GO_TO_NEXT_STEP:
      return { ...state, currentStep: state.currentStep + 1 };
    case ActionTypes.LOAD_JOURNEY_LOADER:
      return { ...state, journeyLoader: action.payload };
    case ActionTypes.GO_TO_PREV_STEP:
      if (state.currentStep > 0) {
        return { ...state, currentStep: state.currentStep - 1 };
      } else {
        return state;
      }
    case ActionTypes.JOURNEY_CLEAN_UP:
      return initialState;
    case ActionTypes.JOURNEY_COMPLETE:
      return { ...state, completed: true };
    case ActionTypes.UPDATE_JOURNEY_ACTION:
      return { ...state, journeyAction: action.payload };
    default:
      return state;
  }
}

export default journeyReducer;
