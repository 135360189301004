import { ToastError } from './toast.service';
import { logoutUser } from 'utils/cookiesWrapper';
import { USER_NOT_FOUND } from 'utils/constants';

export const zionErrorHandler = async err => {
    if (err.status === 401) {
        ToastError('Session Expired');
        logoutUser();
    } else if (err.status === 503) {
        ToastError('Service Unavailable');
    } else if (err.status === 404 && err.url && err.url.includes('/login')) {
        ToastError(USER_NOT_FOUND, 0);
    }
};
