import { ContainerState, ContainerActions } from './types';
import ActionTypes from './constants';

// The initial state of the App
export const initialState: ContainerState = {
  loading: false,
  error: false,
  currentUser: '',
  userData: {
    userId: '',
    userName: '',
    emailId: '',
    assignedTenants: [],
    assignedRoles: [],
    rtkn: '',
    journeys: [],
  },
  closeWindow: false,
};

// Take this container's state (as a slice of root state), this container's actions and return new state
function appReducer(
  state: ContainerState = initialState,
  action: ContainerActions,
): ContainerState {
  switch (action.type) {
    case ActionTypes.LOAD_USER_SUCCESS:
      return { ...state, userData: action.payload };
    case ActionTypes.CLOSE_WINDOW:
      return { ...state, closeWindow: true };
    default:
      return state;
  }
}

export default appReducer;
