import * as styledComponents from 'styled-components';
export interface IThemeInterface {
  primaryfont: string;
  secondaryFont: string;
  white: string;
  shadow4: string;
  shadow: string;
  shadow2: string;
  shadow3: string;
  gray: string;
  gray2: string;
  gray3: string;
  gray4: string;
  gray5: string;
  gray6: string;
  gray7: string;
  lightGray: string;
  black: string;
  primary: string;
  blue: string;
  blue2: string;
  sucess: string;
  sucess2: string;
  danger: string;
  dangerShadow: string;
  primaryBlue: string;
  silver: string;
  silver2: string;
  silver3: string;
  warning: string;
  greenShadow: string;
  lightGreen: string;
  blueShadow: string;
  tint: string;


  _font: string;
  _gray7: string;
  _gray6: string;
  _gray5: string;
  _gray4: string;
  _gray3: string;
  _gray2: string;
  _gray: string;

  _teal6: string;
  _teal5: string;
  _teal4: string;
  _teal3: string;
  _teal2: string;
  _teal1: string;
  _teal: string;

  _tealGrad1: string;
  _tealGrad2: string;

  _orange5: string;
  _orange4: string;
  _orange3: string;
  _orange2: string;
  _orange1: string;

  _orangeGrad1: string;
  _orangeGrad2: string;

  _error4: string;
  _error3: string;
  _error2: string;
  _error1: string;

  _warning4: string;
  _warning3: string;
  _warning2: string;
  _warning1: string;

  _success5: string;
  _success4: string;
  _success3: string;
  _success2: string;
  _success1: string;

  _blue: string;
  _violet: string;
  _indigo: string;
  _orange: string;
  _yellow: string;
  _green: string;
  _white: string;

  _shadowColor1: string;
  _teal1Shadow: string;
  _teal2Shadow2: string;
  _teal2Shadow3: string;
  _whiteShadow: string;
  _whiteShadow1: string;
  _whiteShadow6: string;
  _grayShadow1: string;
  _grayShadow2: string;
  _greenShadow: string;
}

export const theme  = {
  primaryfont: '"Roboto",sans-serif',
  secondaryFont: '"Work Sans", sans-serif',
  white: '#FFFFFF',
  shadow4: 'rgba(0, 0, 0, 0.1)',
  shadow: 'rgba(0, 0, 0, 0.12)',
  shadow2: 'rgba(0, 0, 0, 0.045)',
  shadow3: 'rgba(0, 0, 0, 0.65)',
  gray: '#696974',
  gray2: '#E2E2EA',
  gray3: '#d9d9d9',
  gray4: '#D5D5DC',
  gray5: '#f1f1f5',
  gray6: '#92929D',
  gray7: '#171725',
  lightGray: '#B5B5BE',
  black: '#44444F',
  primary: '#1F81ED',
  blue: '#298CF8',
  blue2: '#1890ff',
  sucess: '#1EC685',
  sucess2: '#0acf83',
  danger: '#EB5757',
  dangerShadow: '#FFF3F3',
  primaryBlue: '#152F4A',
  silver: '#FAFAFB',
  silver2: '#BDBDBD',
  silver3: '#fafafa',
  warning: '#F2994A',
  greenShadow: 'rgb(242, 255, 250)',
  lightGreen: '#E2FAF0',
  blueShadow: '#F2F8FF',
  tint: '#E6F1F4',

  _font: '\'Nunito\', sans-serif',

  _gray7: '#252525',
  _gray6: '#555555',
  _gray5: '#858585',
  _gray4: '#C5C5C5',
  _gray3: '#E5E5E5',
  _gray2: '#F5F5F5',
  _gray: '#F9F9F9',

  _teal6: '#224D5B',
  _teal5: '#336676',
  _teal4: '#4F8293',
  _teal3: '#83AAB7',
  _teal2: '#CFE0E5',
  _teal1: '#E6F1F4',
  _teal: '#F4F9FB',

  _tealGrad1: 'linear-gradient(96.45deg, #698F9A 0%, #255A68 100%)',
  _tealGrad2: 'linear-gradient(180deg, #255A68 0%, #698F9A 100%)',

  _orange5: '#EF420F',
  _orange4: '#FF6639',
  _orange3: '#FF7D57',
  _orange2: '#FFE6DF',
  _orange1: '#FFF3F0',

  _orangeGrad1: 'linear-gradient(96.45deg, #FF6E65 0%, #FF8957 100%)',
  _orangeGrad2: 'linear-gradient(96.45deg, #FF5D52 0%, #FF7940 100%)',

  _error4: '#E24A4A',
  _error3: '#FF2C2C',
  _error2: '#DA1414',
  _error1: '#FBE7E7',

  _warning4: '#F9E000',
  _warning3: '#EE9F28',
  _warning2: '#DB9000',
  _warning1: '#F8F2E5',

  _success5: '#24D092',
  _success4: '#00F99F',
  _success3: '#0CE06E',
  _success2: '#287D3C',
  _success1: '#E9F2EB',

  _blue: '#4FC7FA',
  _violet: '#8676FF',
  _indigo: '#023AFF',
  _orange: '#FD756C',
  _yellow: '#FF9F2E',
  _green: '#00B998',
  _white: '#FFF',


  _shadowColor1: 'rgba(0, 0, 0, 0.1)',
  _teal2Shadow2: 'rgb(207 224 229 / 50%)',
  _teal2Shadow3: 'rgb(207 224 229 / 25%)',
  _teal1Shadow:  'rgb(244 249 251 / 40%)',
  _whiteShadow:  'rgb(255 255 255 / 80%)',
  _whiteShadow1: 'rgb(255 255 255 / 10%)',
  _whiteShadow6: 'rgb(255 255 255 / 60%)',
  _grayShadow1:  'rgba(59, 99, 112, 0.1)',
  _grayShadow2:  'rgba(85, 85, 85, 0.6)',
  _greenShadow:  'rgba(0, 249, 159, 0.15)',
};


const {
  default: styled,
  css,
  createGlobalStyle,
  keyframes,
  ThemeProvider,
} = styledComponents as any;

export { css, createGlobalStyle, keyframes, ThemeProvider };
export default styled;
