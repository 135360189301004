export const ALLOWED_MANAGEMENT_USER = ['ADMIN'];

export const ALLOWED_LAYOUT_DEBUG_USER = ['ADMIN', 'DEV', 'QA'];

// export const ALLOWED_ONLY_PARTNER_VIEW = ['CONTENT PARTNER'];

// export const ALLOWED_ONLY_EXTERNAL_VIEW = ['EXTERNAL USER'];

export const CONTENT_PARTNER_ROLE = 'content partner';

export const PODCAST_PARTNER_ROLE = 'podcast partner';

export const EXTERNAL_USER_ROLE = 'external user';
