import { createGlobalStyle, theme } from 'styles/styled-components';
const GlobalStyle = createGlobalStyle`
* :focus{
	outline:none;
}
html,
body {
	width: 100%;
	height: 100%;
	line-height: 1.5;
	background:${theme._white};
	font-family: ${theme._font};
	font-size: 16px;
	font-weight: 400;
}
body.fontLoaded,
button{
	font-family: ${theme._font};
}
h1,
h2,
h3,
h4,
h5,
h6,
label {font-family:${theme._font};}

#app {
	min-height: 100%;
	min-width: 100%;
	/* width:100vh;
	position:fixed; */
	background-color: ${theme._gray};
	/* .ant-table-thead > tr > th{padding:0.15rem 0.25rem;
	div.ant-table-column-sorters{padding:0 1rem;}
	}; */
}
.anticon{vertical-align: middle;}
.dynamicTable .ant-table-filter-trigger-container{
	width:50px;
}
.dynamicTable .ant-table-column-sorters{
   width:150px;
	padding:0 0.5rem;
}
.custommodal {
	.ant-modal-content{
		background:transparent;
		box-shadow:none;
	}
	.ant-modal-body{
		padding:0px;
	}
}
/*above written new important css it's necessary for all pages */

/* temproray css for new design update */

/* temproray css for new design update */

.overLap{
	/* top:-10.25rem; */
	margin-top:-10.25rem;
	position:relative;
	box-shadow: 6px 6px 60px rgba(59, 99, 112, 0.1);
}

.height100vh{height:100vh;}

/* text alignment class */
.text_center{text-align:center;}
.text_left{text-align:left;}
.text_right{text-align:right;}
.text_trunk {input{white-space:nowrap;overflow:hidden;text-overflow:ellipsis;}}
.text_ellips{white-space:nowrap;overflow:hidden;text-overflow:ellipsis;}

/* text color class */
.text_white{color:${theme._white};}
.text_teal6{color:${theme._teal6};}
.text_teal5{color:${theme._teal5};}
.text_teal4{color:${theme._teal4};}
.text_teal3{color:${theme._teal3};}

.text_orange4{color:${theme._orange4};}
.text_orange5{color:${theme._orange5};}

.text_gray3{color:${theme._gray3};}
.text_gray4{color:${theme._gray4};}
.text_gray5{color:${theme._gray5};}
.text_gray4{color:${theme._gray4};}
.text_gray3{color:${theme._gray3};}
.text_gray6{color:${theme._gray6};}
.text_gray7{color:${theme._gray7};}
.text_success4{color:${theme._success4};}
.text_success5{color:${theme._success5};}
.text_error2{color:${theme._error2};}
.text_size1{font-size:1rem;}
.text_size2{font-size:1.25rem;}
.text_size3{font-size:1.5rem;}

.text_success4{color:${theme._success4};}
.text_success5{color:${theme._success5};}
.text_warning2{color:${theme._warning2};}
.bg_teal1{background:${theme._teal1};}
.bg_tealGrad1{background:${theme._tealGrad1};}

.bg_white{background-color:${theme._white};}
.bg_greenShadow{background-color:#E9F2EB;}
.bg_yellowShadow{background-color:#F8F2E5;}
.bg_orangeShadow{background-color:#FFE6DF;}
.bg_parent{td{background-color:inherit;}}
.bg_gray3{background:${theme._gray3};}
/* shadow  */

.box_shadow1{box-shadow: 6px 6px 3.75rem rgba(59, 99, 112, 0.1);}
/* spacing */
.mt_1{margin-top:0.25rem;}
.mt_2{margin-top:0.5rem;}
.mt_3{margin-top:0.75rem;}
.mt_4{margin-top:1rem;}
.mt_5{margin-top:1.25rem;}
.mt_6{margin-top:1.5rem;}

.mr_0{margin-right:0 !important;}
.mr_1{margin-right:0.25rem;}
.mr_2{margin-right:0.5rem;}
.mr_3{margin-right:0.75rem;}
.mr_4{margin-right:1rem;}
.mr_5{margin-right:1.25rem;}
.mr_6{margin-right:1.5rem;}

.ml_1{margin-left:0.25rem;}
.ml_2{margin-left:0.5rem;}
.ml_3{margin-left:0.75rem;}
.ml_4{margin-left:1rem;}
.ml_5{margin-left:1.25rem;}
.ml_6{margin-left:1.5rem;}

.mt_1{margin-top:0.25rem;}
.mt_2{margin-top:0.5rem;}
.mt_3{margin-top:0.75rem;}
.mt_4{margin-top:1rem;}
.mt_5{margin-top:1.25rem;}
.mt_6{margin-top:1.5rem;}

.mb_0{margin-bottom:0rem;}
.mb_1{margin-bottom:0.25rem !important;}
.mb_2{margin-bottom:0.5rem !important;}
.mb_3{margin-bottom:0.75rem!important;}
.mb_4{margin-bottom:1rem !important;}
.mb_5{margin-bottom:1.25rem;}
.mb_6{margin-bottom:1.5rem;}

.p_1{padding:0.25rem;}
.p_2{padding:0.5rem;}
.p_3{padding:0.75rem;}
.p_4{padding:1rem;}
.p_5{padding:1.25rem;}
.p_6{padding:1.5rem;}

.pt_0{padding-top:0rem !important;}
.pt_1{padding-top:0.25rem !important;}
.pt_2{padding-top:0.5rem !important;}
.pt_3{padding-top:0.75rem !important;}
.pt_4{padding-top:1rem !important;}
.pt_5{padding-top:1.25rem;}
.pt_6{padding-top:1.5rem;}

.pt6{padding-top:6rem;}

.pl_1{padding-left:0.25rem;}
.pl_2{padding-left:0.5rem;}
.pl_3{padding-left:0.75rem;}
.pl_4{padding-left:1rem;}

.pl_6{padding-left:1.5rem;}

.pr_2{padding-right:0.5rem;}
.pr_3{padding-right:0.75rem;}
.pr_4{padding-right:1rem;}

.pb_4{padding-bottom:1rem;}
.pb_5{padding-bottom:1.25rem;}
.pb_6{padding-bottom:1.5rem;}
.border_LRT_6{border-radius:1.5rem 1.5rem 0 0;}
.cursor_pointer{cursor: pointer;}
.td_filter{
	.ant-select-selector{
	background:${theme._white};
	border-radius:0.75rem;
	border-color:transparent;
	padding:1rem 1.5rem;}
	&:hover {.ant-select-selector {
		border-color:transparent;
	}}
	}
.float_right{float:right;}
.text_left{text-align:left !important;}
.deletedRow {
	cursor: not-allowed;
	position: relative;
	background-color: #f0f0f0;
	&::after {
		content: '';
		position: absolute;
		height: 100%;
		width: 100%;
		z-index: 10000001;
		left: 0px;
	}
}
.overlay-root-container {
	max-height: 200px;
	overflow-y: scroll;
	box-shadow: 6px 6px 3.75rem rgba(59, 99, 112, 0.1);
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% {  transform: rotate(359deg); }
}
.spin {animation: spin 2s linear infinite;}


.border-radius_1{border-radius:0.25rem;}
.border-radius_2{border-radius:0.5rem;}
.border-radius_3{border-radius:0.75rem;}
.border-radius_4{border-radius:1.0rem;}
.border-radius_4{border-radius:1.25rem;}
.contentBox{border-radius:1.5rem; min-height:75vh;}

.customModal{
	.ant-modal-content{
		border-radius:0.75rem;
		box-shadow: 6px 6px 60px rgba(59, 99, 112, 0.1);}
}
.widgetModal{
	.ant-modal-content{
		border-radius:1.5rem;
		box-shadow: 6px 6px 60px rgba(59, 99, 112, 0.1);
		height: 100%;
		overflow-y: auto;
		position: fixed;
		right: 0;
		width: 100%;
		}
		.ant-modal-body{padding: 2.25rem 3.5rem;}
		.ant-modal-close{
			position: fixed;
			top:2.75rem; 
			right:1.25rem;
			color:${theme._white};
			.ant-modal-close-x{font-size:1.5rem;}
			}

}

.scrollX{
	overflow-y: hidden;
	overflow-x: auto;
	scroll-behavior: smooth;
	  ::-webkit-scrollbar {
		width: 0;
		}
}
.vertical_center{position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);}
	.pull_right{float:right;}
	.fixed_buttonRight{
		position:fixed;
      bottom:46px;
		right:2%;
		padding: 1.084rem 1.085rem;
		.iconRight{margin-left:0 !important;}
		.hover_text{display:none;}
		&:focus,
		&:hover{
			.iconRight{margin-left:0.5rem !important;}
			padding: 1.084rem 1.05rem;
			transition:0.3s ease-in-out;
			.hover_text{display:inline-block;}
			}
		}
	.custom_switch{
		background:${theme._gray3};
		min-width:2.5rem;
		&:after{background:${theme._gray5};}
		&.ant-switch-checked{
			background:${theme._teal4};
		&:after{background:${theme._teal1};}
		}
	}
	.toggle_btn{
		background:${theme._teal4};
		min-width:2.5rem;
		&:after{background:${theme._teal1};}
		&.ant-switch-checked{
			background:${theme._teal4};
		&:after{background:${theme._teal1};}
		}
	}

.saveFIlterModal{
	box-shadow:6px 6px 60px rgba(59, 99, 112, 0.1);
	.ant-modal-content{border-radius:1rem;}
	.ant-modal-header{
		border-radius:0.75rem 0.75rem 0 0 ;
		border-bottom:transparent;
	}
	.ant-modal-title{color:${theme._teal6};
		font-size:1.25rem;
		line-height:1.5rem;
	}
	.ant-modal-body{padding:0.5rem 2rem 1.5rem 2rem;
	.ant-input-affix-wrapper{border:none;
		color:${theme._gray6};
		font-size:1rem;
		font-weight:400;
		line-height:1.5rem;
		border-bottom: 1px solid ${theme._gray6};
		border-radius:0;
		&:focus,
		&-focused{
		border-bottom: 1px solid ${theme._teal4};
		 box-shadow:none;}
	}
	}
	&.min_height25rem{
		.ant-modal-body{min-height:25rem;}
	}
}
	.ant-input-affix-wrapper-focused,
	.ant-input:focus{box-shadow:none;}
	.d_inlineFlex{display:inline-flex;}
	.ant-table-filter-dropdown{box-shadow:none;}
	.select_tenant{
    list-style: none;
    margin: 0;
    padding: 0;
    box-shadow: 12px 12px 60px rgb(59 99 112 / 20%);
    border-radius: 0.75rem;
    background: ${theme._white};
    min-width: 7rem;
    padding: 1rem 0;
	.ant-select-item{margin: 0;
    padding: 0.5rem 1.5rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    color: #555555;
    &:hover,&:focus,
	 &.ant-select-item-option-active,
	 &.ant-select-item-option-selected{
    background: #E6F1F4;
    color: #336676;
    cursor: pointer;
    }
    }

	}
	.text_light{font-weight:300;}
	.border_t_gray{border-top: 0.75px solid ${theme._gray3};}
	.songEditForm{width:100%;height:65vh; overflow-y:scroll;padding-right:1.25rem;
		&::-webkit-scrollbar{
			width: 0.75rem;
			background-color:${theme._white};
		}
		&::-webkit-scrollbar-thumb{
			border-radius: 0.5rem;
			box-shadow: none;
			background-color: transparent;
		}
	}
	.border_b_gray3{border-bottom: 0.5px solid ${theme._gray3};}
	.meta_id{font-size:0.75rem;opacity:0.25;}

	.circle{border-radius:50%;}

	.artist_bank_detail{ display: inline-block;}
	.status-red{ background-color: #F4D0D6;}

	.status-red{ background-color: #F4D0D6}
	.form-group {
		border: 1px solid ${theme._orange4};
		border-radius: 0.5rem;
		color:${theme._orange4};
		margin-bottom: 10px;
		.ant-form-item {
			margin-bottom: 0px;
		}
	}

	.form-group-search {
		margin: 2px 0;
		border-radius: 0.5rem;
	}

	.form-group-inline-label {
		position: relative;
		padding: 0 11px;
		color: ${theme._orange4};
		font-weight: 600;
		font-size: 14px;
		text-align: center;
		background-color: ${theme._orange1};
		border:transparent;
		border-right:1px solid ${theme._orange4};
		-webkit-transition: all .3s;
		transition: all .3s;
		width: 1px;
		white-space: nowrap;
		vertical-align: middle;
		display: table-cell;
		border-radius: 0.5rem 0 0 0.5rem;
		min-height: 36px;
	}

	.status-red{ background-color: #F4D0D6;}

	.alert-info {
		background: transparent;
		font-size: 12px;
		padding-left: 18px;
		border: none;
		.ant-alert-icon {
			top: 11px;
			left: 2px;
		}
	}

	.reason-dropdown {
    max-width: 400px;
    .ant-dropdown-menu-item,
    .ant-dropdown-menu-submenu-title {
      white-space: unset;
      max-width: 450px;
      width: unset !important;
    }
  }

  .action-buttons {
	  .ant-btn {
			font-size: 4px;
			width: 18px;
			height: 18px;
			line-height: 0px
			min-width: 18px;
			span {
				font-size: 9px;
			}
	  	}
  } 
`;

export default GlobalStyle;
