/*
 * AppConstants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'yourproject/YourComponent' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 */

enum ActionTypes {
  LOAD_USER = 'boilerplate/App/LOAD_USER',
  LOAD_USER_SUCCESS = 'boilerplate/App/LOAD_USER_SUCCESS',
  LOAD_USER_ERROR = 'boilerplate/App/LOAD_USER_ERROR',
  CLOSE_WINDOW = 'boilerplate/App/CLOSE_WINDOW',
  SET_APP_KEY = 'boilerplate/App/SET_APP_KEY',
  WYNK_MUSIC_CONFIG_FETCH = 'boilerplate/Config/WYNK_MUSIC_CONFIG_FETCH',
  WYNK_MUSIC_CONFIG_SUCCESS = 'boilerplate/Config/WYNK_MUSIC_CONFIG_SUCCESS',
  XSTREAM_CONFIG_FETCH = 'boilerplate/Config/XSTREAM_CONFIG_FETCH',
  XSTREAM_CONFIG_SUCCESS = 'boilerplate/Config/XSTREAM_CONFIG_SUCCESS',
  FETCH_MY_PROFILE = 'boilerplate/App/MY_PROFILE',
  LAYOUT_CONFIG_FETCH = 'boilerplate/Config/LAYOUT_CONFIG_FETCH',
  LAYOUT_CONFIG_SUCCESS = 'boilerplate/Config/LAYOUT_CONFIG_SUCCESS',
  SEND_SLACK_NOTIFICATION = 'boilerplate/App/SEND_SLACK_NOTIFICATION',
  MULTI_ARTWORK_SLACK_NOTIFICATION = 'boilerplate/App/MULTI_ARTWORK_SLACK_NOTIFICATION',
}

export default ActionTypes;
