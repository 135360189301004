/* eslint-disable no-param-reassign */
import Domain from 'models/domain.models';
import Realm from 'models/realm.model';
import localStorageWrapper from './localStorageWrapper';

export function getRealm(domain?: string) {
  if (domain === undefined) {
    domain = localStorageWrapper.getItem('appKey');
  }
  switch (domain) {
    case Domain.WYNK:
      return Realm.WYNK_MUSIC;
    case Domain.XSTREAM:
      return Realm.XSTREAM;
    case Domain.RAJ_TV:
      return Realm.RAJ_TV;
    case Domain.DHIME_MUSIC:
      return Realm.DHIME_MUSIC;
    case Domain.ENTERR10:
      return Realm.ENTERR10;
    case Domain.IQ_VIDEO:
      return Realm.IQ_VIDEO;
    case Domain.DISCOVER:
      return Realm.DISCOVER;
    case Domain.UGP:
      return Realm.UGP;
    default:
      return Realm.WYNK_MUSIC;
  }
}

export const isMusicDomain = (domain?: string) => {
  if (!domain) {
    domain = localStorageWrapper.getItem('appKey');
  }
  return domain === Domain.WYNK || domain === Domain.DHIME_MUSIC || domain === Domain.DISCOVER || domain === Domain.UGP;
};

export const isVideoDomain = (domain?: string) => {
  if (!domain) {
    domain = localStorageWrapper.getItem('appKey');
  }
  return (
    domain === Domain.XSTREAM || domain === Domain.RAJ_TV || domain === Domain.ENTERR10 || domain === Domain.IQ_VIDEO
  );
};
