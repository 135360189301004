enum ContentTypes {
  SONG = 'SONG',
  UNAPPROVED_SONG = 'UNAPPROVED_SONG',
  ALBUM = 'ALBUM',
  COMPILATION = 'COMPILATION',
  ARTIST = 'ARTIST',
  PLAYLIST = 'PLAYLIST',
  PLAYLISTS = 'PLAYLISTS',
  TILE = 'TILE',
  DYNAMIC_TILE = 'DYNAMIC_TILE',
  ARTIST_PLAYLIST = 'ARTIST_PLAYLIST',
  FEATURED_COLLECTION = 'FEATURED_COLLECTION',
  MUSIC_COLLECTION = 'MUSIC_COLLECTION',
  GENERIC_COLLECTION = 'GENERIC_COLLECTION',
  ARTIST_COLLECTION = 'ARTIST_COLLECTION',
  LEGACY_PLAYLIST = 'LEGACY_PLAYLIST',
  LEGACY_COLLECTION = 'LEGACY_COLLECTION',
  LEGACY_EMPTY_COLLECTION = 'LEGACY_EMPTY_COLLECTION',
  COLLECTION = 'COLLECTION',
  SONG_COLLECTION = 'SONG_COLLECTION',
  MODULE = 'MODULE',
  TILES = 'TILES',
  MUSIC_MERGED_COLLECTION = 'MUSIC_MERGED_COLLECTION',
  MERGED_COLLECTION = 'MERGED_COLLECTION',
  PODCAST_EPISODE = 'PODCAST_EPISODE',
  PODCAST = 'PODCAST',
  PODCAST_UNAPPROVED = 'PODCAST_UNAPPROVED',
  PODCAST_EPISODE_COLLECTION = 'PODCAST_EPISODE_COLLECTION',
  PODCAST_COLLECTION = 'PODCAST_COLLECTION',
  LAYOUT_EXPERIMENT = 'LAYOUT',
  LAYOUT_CLIENT = 'CLIENT',
  LAYOUT_BACKEND = 'BACKEND',
  TAGS = 'TAGS',
  ARTIST_CLAIM_REQUESTS = 'ARTIST_CLAIM_REQUESTS',
  REPORTS = 'REPORTS',
  SEARCH_QUERIES = 'SEARCH_QUERIES',
  LABELS = 'LABELS',
  DISCOVER_CATALOG = 'DISCOVER_CATALOG',
  CONTENT_MANAGEMENT = 'CONTENT_MANAGEMENT',
}

export default ContentTypes;
