/*
 * Journey Constants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'yourproject/YourComponent' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'yourproject/YourContainer/YOUR_ACTION_CONSTANT';
 */

enum ActionTypes {
    TEMP_JOURNEY_DATA_FETCH = 'boilerplate/Journey/TEMP_JOURNEY_DATA_FETCH',
    TEMP_JOURNEY_DATA_SUCCESS = 'boilerplate/Journey/TEMP_JOURNEY_DATA_SUCCESS',
    PUBLISH_COLLECTION_REQUEST = 'boilerplate/Journey/SAVE_COLLECTION_REQUEST',
    PUBLISH_COLLECTION_SUCCESS = 'boilerplate/Journey/SAVE_COLLECTION_SUCCESS',
    PUBLISH_COLLECTION_ERROR = 'boilerplate/Journey/SAVE_COLLECTION_ERROR',
    UPDATE_TEMP_COLLECTION = 'boilerplate/Journey/UPDATE_TEMP_COLLECTION',
    UPDATE_TEMP_EDIT_COLLECTION = 'boilerplate/Journey/UPDATE_TEMP_EDIT_COLLECTION',
    UPDATE_CURRENT_STEP = 'boilerplate/Journey/UPDATE_CURRENT_STEP',
    GO_TO_NEXT_STEP = 'boilerplate/Journey/GO_TO_NEXT_STEP',
    GO_TO_PREV_STEP = 'boilerplate/Journey/GO_TO_PREV_STEP',
    JOURNEY_CLEAN_UP = 'boilerplate/Journey/JOURNEY_CLEAN_UP',
    JOURNEY_COMPLETE = 'boilerplate/Journey/JOURNEY_COMPLETE',
    LOAD_JOURNEY_LOADER = 'boilerplate/Journey/LOAD_JOURNEY_LOADER',
    VIEW_COLLECTION_REQUEST = 'boilerplate/Journey/VIEW_COLLECTION_REQUEST',
    SYNC_COLLECTION = 'boilerplate/Journey/SYNC_COLLECTION',
    UPDATE_JOURNEY_ACTION = 'boilerplate/Journey/UPDATE_JOURNEY_ACTION',
}

export default ActionTypes;
