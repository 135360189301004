import Cookies from 'universal-cookie';
import { USER_LOGGED_IN_DEFAULT_KEY, COOKIES_DEFAULT_PATH } from './constants';

const cookies = new Cookies();

const getCookies = (key) => {
    return cookies.get(key);
};

const setCookies = (key, value, path = COOKIES_DEFAULT_PATH) => {
    cookies.set(key, value, { path: path });
};

const removeCookies = (key, path = COOKIES_DEFAULT_PATH) => {
    cookies.remove(key, { path: path });
};

const logoutUser = () => {
    cookies.remove(USER_LOGGED_IN_DEFAULT_KEY, { path: COOKIES_DEFAULT_PATH });
    location.href = '/';
};

const loggedInCookies = () => {
    return cookies.get(USER_LOGGED_IN_DEFAULT_KEY);
};

export { getCookies, setCookies, removeCookies, logoutUser, loggedInCookies };
