/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import GlobalStyle from '../../global-styles';
import { makeSelectUserData, makeSelectWindow, selectConfig } from 'core/App/selectors';
import { loggedInCookies } from 'utils/cookiesWrapper';

import { useInjectReducer } from 'utils/injectReducer';
import ConfigReducer from './configReducer';
import JourneyReducer from '../Journey/reducer';
import saga from './saga';
import { fetchMyProfile } from './actions';
import { useInjectSaga } from 'utils/injectSaga';
import { ConfigContext } from './appContexts';
import Apps from 'models/apps.model';
import MspRouter from 'components/MspRouter/Loadable';
import ZionRouter from 'components/ZionRouter/Loadable';
import { ThemeProvider } from 'styles/styled-components';
import { theme } from 'styles/styled-components';
import { hot } from 'react-hot-loader/root';

const isLoggedIn = () => {
  return loggedInCookies() ? true : false;
};

const stateSelector = createStructuredSelector({
  config: selectConfig,
  userData: makeSelectUserData(),
  closeWindow: makeSelectWindow(),
});

function App() {

  // Injection App Saga
  useInjectSaga({ key: 'global', saga: saga });

  // Injecting Config Reducer
  useInjectReducer({ key: 'config', reducer: ConfigReducer });
  // Injecting Journey Reducer for collection edits from browse journey
  useInjectReducer({ key: 'journey', reducer: JourneyReducer });

  const dispatch = useDispatch();

  // Store Cookies and User Data, after login
  const { config, userData, closeWindow } = useSelector(stateSelector);

  useEffect(() => {
    if (isLoggedIn()) {
      dispatch(fetchMyProfile());
    }
  }, []);

  useEffect(() => {
    if (closeWindow) {
      window.close();
    }
  }, [closeWindow]);


  return (
    <>
      <ConfigContext.Provider value={{ ...config, userData }}>
        <AppRouter />
      </ConfigContext.Provider>
      <GlobalStyle />
    </>
  );
}

export default hot(App);

const AppRouter = (() => {
  switch (process.env.APP_NAME) {
    case Apps.MSP: return <MspRouter isLoggedIn={isLoggedIn} />;
    default: return (
      <ThemeProvider theme={theme}>
        <ZionRouter isLoggedIn={isLoggedIn} />
      </ThemeProvider>
    );
  }
});

