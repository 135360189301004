/* eslint-disable default-param-last */
import ContentTypes from 'models/contentTypes.model';
import { ConfigState, ContainerActions } from './types.d';
import ActionTypes from './constants';

export const configInitialState: ConfigState = {
  wynkMusic: {
    langs: [],
    zionDropdownLanguageListDefault: [],
    pkgTypes: {},
    cps: {},
    cpLabelMap: {},
    tags: {
      genre: [],
      mood: [],
      others: [],
    },
    contentType: [],
    podcastCategories: [],
    podcastPartners: [],
    artistEra: [],
    artistType: [],
    artistRoleType: [],
    artistGenre: [],
    experimentTypes: [],
    albumTypes: [],
    versions: [],
    revenueperstream: {},
    themeTaxonomy: {},
    workflowStateList: [],
    regionTypes: [],
    cmsAlert: null,
    discoverConfig: {
      categoryList: [],
      partnerList: [],
      contentIdList: [],
      templateList: [],
      topicList: [],
      actionLinkList: [],
      propertyList: [],
    },
  },
  xstream: {
    langs: [],
    cps: {},
    tags: {
      genre: [],
      mood: [],
      others: [],
    },
    segments: [],
    contentType: [],
    genre: [],
    experimentTypes: [],
    revenueperstream: {},
    xTags: [],
  },
  appKey: '',
  userData: {
    userId: '',
    userName: '',
    emailId: '',
    assignedTenants: [],
    assignedRoles: [],
    rtkn: '',
    journeys: [],
  },
  layout: {
    fields: [],
    templates: {},
    clients: [],
    attributes: {},
    fieldAttribute: {},
    sources: {
      WYNK_MUSIC: {},
      XSTREAM: {},
    },
    sourceContentIdMap: {},
    actionTypes: [],
    contentTypes: [],
    sectionAttribute: [],
    susanoConfig: { SusanoTag: [], DynamicModuleStrategyArrays: [] },
  },
};

function ConfigReducer(state: ConfigState = configInitialState, action: ContainerActions): ConfigState {
  switch (action.type) {
    case ActionTypes.WYNK_MUSIC_CONFIG_SUCCESS:
      return { ...state, wynkMusic: { ...configInitialState.wynkMusic, ...action.payload } };
    case ActionTypes.XSTREAM_CONFIG_SUCCESS: {
      const { contentType } = action.payload;
      const updatedConfig = {
        ...action.payload,
        contentType: contentType ? contentType.concat(ContentTypes.COLLECTION) : [ContentTypes.COLLECTION],
      };
      return { ...state, xstream: updatedConfig };
    }
    case ActionTypes.LAYOUT_CONFIG_SUCCESS:
      return { ...state, layout: action.payload };
    case ActionTypes.SET_APP_KEY:
      return { ...state, appKey: action.payload };
    default:
      return state;
  }
}

export default ConfigReducer;
