import { getCookies, setCookies } from './cookiesWrapper';
import { USER_LOGGED_IN_DEFAULT_KEY } from './constants';
import { handleError } from 'services/handleError.service';
import { getRealm } from './getRealm';
import localStorageWrapper from './localStorageWrapper';
import AES from 'crypto-js/aes';

export class ResponseError extends Error {
  public response: Response;

  constructor(response: Response) {
    super(response.statusText);
    this.response = response;
  }
}
/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed JSON from the request
 */
function parseJSON(response) {
  if (response.status === 204 || response.status === 205) {
    return null;
  }

  return response.json();
}

function responseText(response) {
  if (response.status === 204 || response.status === 205) {
    return null;
  }
  return response.text();
}

/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param  {object} response   A response from a network request
 *
 * @return {object|undefined} Returns either the response, or throws an error
 */
function checkStatus(response, disableToastOnError: boolean = false) {
  if (response.status >= 200 && response.status < 300) {
    if (response.headers.get('ntkn')) {
      setCookies(USER_LOGGED_IN_DEFAULT_KEY, response.headers.get('ntkn'));
    }
    return response;
  }
  const error = new ResponseError(response);
  // error.response = response;
  return handleError(error.response, disableToastOnError);
  // throw error;
}

function getRequestUrl(url) {
  const appKey = localStorageWrapper.getItem('appKey');
  const REALM = getRealm(appKey);

  if (url.includes('realm')) return url;

  if (url.includes('?')) return `${url}&realm=${REALM}`;

  return `${url}?realm=${REALM}`;
}

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           The response data
 */
export default async function request(
  url: string,
  options?: RequestInit,
  disableToastOnError?: boolean,
): Promise<{} | { err: ResponseError }> {
  const fetchResponse = await fetch(getRequestUrl(url), options);
  const response = await checkStatus(fetchResponse, disableToastOnError);
  return parseJSON(response);
}

export async function getRequest<T>(
  url: string,
  disableToastOnError?: boolean,
  headers?: { [key: string]: any },
): Promise<T | { err: ResponseError }> {
  const fetchResponse = await fetch(getRequestUrl(url), {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      rtkn: getCookies(USER_LOGGED_IN_DEFAULT_KEY),
      ...headers,
    },
  });
  const response = await checkStatus(fetchResponse, disableToastOnError);
  return parseJSON(response);
}

export async function getRequestWithTextResponse<T>(
  url: string,
  disableToastOnError?: boolean,
): Promise<T | { err: ResponseError }> {
  const fetchResponse = await fetch(getRequestUrl(url), {
    method: 'GET',
    headers: {
      'Content-Type': 'application/text',
      rtkn: getCookies(USER_LOGGED_IN_DEFAULT_KEY),
    },
  });
  const response = await checkStatus(fetchResponse, disableToastOnError);
  return responseText(response);
}

export async function postRequest<T>(
  url: string,
  data: object,
  disableToastOnError?: boolean,
): Promise<T | { err: ResponseError }> {
  const fetchResponse = await fetch(getRequestUrl(url), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      rtkn: getCookies(USER_LOGGED_IN_DEFAULT_KEY),
    },
    body: JSON.stringify(data),
  });
  const response = await checkStatus(fetchResponse, disableToastOnError);
  return parseJSON(response);
}

export async function postRequestFile(
  url: string,
  data: object,
  disableToastOnError?: boolean,
): Promise<{} | { err: ResponseError }> {
  const fetchResponse = await fetch(getRequestUrl(url), {
    method: 'POST',
    headers: {
      rtkn: getCookies(USER_LOGGED_IN_DEFAULT_KEY),
    },
    body: data,
  } as RequestInit);
  const response = await checkStatus(fetchResponse, disableToastOnError);
  return parseJSON(response);
}

export async function putRequestWithTextResponse(
  url: string,
  data: object,
  disableToastOnError?: boolean,
): Promise<{} | { err: ResponseError }> {
  const fetchResponse = await fetch(getRequestUrl(url), {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      rtkn: getCookies(USER_LOGGED_IN_DEFAULT_KEY),
    },
    body: JSON.stringify(data),
  });
  const response = await checkStatus(fetchResponse, disableToastOnError);
  return responseText(response);
}

export async function putRequest(
  url: string,
  data: object,
  disableToastOnError?: boolean,
): Promise<{} | { err: ResponseError }> {
  const fetchResponse = await fetch(getRequestUrl(url), {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      rtkn: getCookies(USER_LOGGED_IN_DEFAULT_KEY),
    },
    body: JSON.stringify(data),
  });
  const response = await checkStatus(fetchResponse, disableToastOnError);
  return parseJSON(response);
}

export async function putS3Request(url: string, file: any, header?: Headers): Promise<{} | { err: ResponseError }> {
  const fetchResponse = await fetch(url, {
    method: 'PUT',
    body: file,
  });
  const response = await checkStatus(fetchResponse);
  return response;
}

export async function deleteRequest(url: string, disableToastOnError?: boolean): Promise<{} | { err: ResponseError }> {
  const fetchResponse = await fetch(getRequestUrl(url), {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      rtkn: getCookies(USER_LOGGED_IN_DEFAULT_KEY),
    },
  });
  const response = await checkStatus(fetchResponse, disableToastOnError);
  const contentType = response.headers.get('content-type');
  if (contentType && contentType.indexOf('application/json') !== -1) {
    return parseJSON(response);
  } else {
    return {};
  }
}

export async function postRequestWithoutHeader(
  url: string,
  data: object,
  disableToastOnError?: boolean,
): Promise<{} | { err: ResponseError }> {
  const fetchResponse = await fetch(getRequestUrl(url), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  const response = await checkStatus(fetchResponse, disableToastOnError);
  return parseJSON(response);
}

export async function postRequestWithTextResponse(
  url: string,
  data: object,
  disableToastOnError?: boolean,
): Promise<{} | { err: ResponseError }> {
  const fetchResponse = await fetch(getRequestUrl(url), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      rtkn: getCookies(USER_LOGGED_IN_DEFAULT_KEY),
    },
    body: JSON.stringify(data),
  });
  const response = await checkStatus(fetchResponse, disableToastOnError);
  return responseText(response);
}

export async function postRequestWithCustomHeader(
  url: string,
  data: object,
  headers,
  disableToastOnError?: boolean,
): Promise<{} | { err: ResponseError }> {
  const fetchResponse = await fetch(getRequestUrl(url), {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(data),
  });
  const response = await checkStatus(fetchResponse, disableToastOnError);
  return responseText(response);
}

export const serialize = obj => {
  const str: string[] = [];
  const tempObj = cleanedObject(obj);
  Object.keys(tempObj)
    .sort()
    .forEach(p => {
      str.push(encodeURIComponent(p) + '=' + encodeURIComponent(tempObj[p]));
    });
  return str.join('&');
};

export const cleanedObject = obj => {
  Object.keys(obj).forEach(key => {
    if (!obj[key] && obj[key] !== 0 && obj[key] !== false) {
      delete obj[key];
    }
    if (Array.isArray(obj[key]) && obj[key].length === 0) {
      delete obj[key];
    }
  });
  return obj;
};

export const encryptString = text => {
  const secretKey = 'V^yNk@$tu@5#1S@M';
  const encrypted = AES.encrypt(text, secretKey);

  return encrypted.toString();
};