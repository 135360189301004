import { CONTENT_PARTNER_ROLE, EXTERNAL_USER_ROLE, PODCAST_PARTNER_ROLE } from 'components/UserInfo/contants';
import User from 'models/user.model';

const userFn = (() => {
  let userDetails: User = {
    emailId: '',
    assignedRoles: [],
    assignedTenants: [],
    userId: '',
    userName: '',
    rtkn: '',
  };

  const getUserData = (): User => userDetails;

  const setUserData = (user) => {
    userDetails = { ...user };
  };

  const hasRoleType = (roleType: string) =>
    userDetails.assignedRoles.some((role) => role.roleName.toUpperCase() === roleType.toUpperCase());
  return { getUserData, setUserData, hasRoleType };
})();

const { getUserData, setUserData, hasRoleType } = userFn;

const isOnlyContentPartnerView = () => {
  const { assignedRoles } = getUserData();
  const ALLOWED_ROLES = assignedRoles ? assignedRoles.map((role: any) => role.roleName.toLowerCase()) : [];
  return ALLOWED_ROLES.includes(CONTENT_PARTNER_ROLE);
};

const isOnlyExternalUserView = () => {
  const { assignedRoles } = getUserData();
  const ALLOWED_ROLES = assignedRoles ? assignedRoles.map((role: any) => role.roleName.toLowerCase()) : [];
  return ALLOWED_ROLES.includes(EXTERNAL_USER_ROLE);
};

const isOnlyPodcastPartnerView = () => {
  const { assignedRoles } = getUserData();
  const ALLOWED_ROLES = assignedRoles ? assignedRoles.map((role: any) => role.roleName.toLowerCase()) : [];
  return ALLOWED_ROLES.includes(PODCAST_PARTNER_ROLE);
};

export {
  getUserData,
  setUserData,
  hasRoleType,
  isOnlyContentPartnerView,
  isOnlyExternalUserView,
  isOnlyPodcastPartnerView,
};
