enum Realm {
  WYNK_MUSIC = 'WYNK_MUSIC',
  XSTREAM = 'XSTREAM',
  RAJ_TV = 'RAJ_TV',
  DHIME_MUSIC = 'DHIME_MUSIC',
  ENTERR10 = 'ENTERR10',
  IQ_VIDEO = 'IQ_VIDEO',
  DISCOVER = 'DISCOVER',
  UGP = 'UGP',
}

export default Realm;
