import { action } from 'typesafe-actions';

import ActionTypes from './constants';

export const loadUser = () => action(ActionTypes.LOAD_USER);

export const userLoaded = (payload) =>
  action(ActionTypes.LOAD_USER_SUCCESS, payload);

export const userLoadingError = (error) =>
  action(ActionTypes.LOAD_USER_ERROR, error);

export const closeWindow = () => action(ActionTypes.CLOSE_WINDOW);

export const setAppkey = payload => action(ActionTypes.SET_APP_KEY, payload);

export const wynkMusicConfigFetch = () => action(ActionTypes.WYNK_MUSIC_CONFIG_FETCH);

export const wynkMusicConfigSuccess = payload => action(ActionTypes.WYNK_MUSIC_CONFIG_SUCCESS, payload);

export const xstreamConfigFetch = () => action(ActionTypes.XSTREAM_CONFIG_FETCH);

export const xstreamConfigSuccess = payload => action(ActionTypes.XSTREAM_CONFIG_SUCCESS, payload);

export const fetchMyProfile = () => action(ActionTypes.FETCH_MY_PROFILE);

export const layoutConfigFetch = () => action(ActionTypes.LAYOUT_CONFIG_FETCH);

export const layoutConfigSuccess = payload => action(ActionTypes.LAYOUT_CONFIG_SUCCESS, payload);

export const sendSlackNotification = payload => action(ActionTypes.SEND_SLACK_NOTIFICATION, payload);

export const sendMultiArtworkSlackNotification = payload => action(ActionTypes.MULTI_ARTWORK_SLACK_NOTIFICATION, payload);
