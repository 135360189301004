function getItem(key: string, defaultValue: any = null) {
    try {
        const value = localStorage.getItem(key) || '';
        return value ? JSON.parse(value) : defaultValue;
    } catch (err) {
        return null;
    }
}

function setItem(key: string, value: any) {
    try {
        localStorage.setItem(key, JSON.stringify(value));
    } catch (err) {
        throw err;
    }
}

function removeItem(key: string) {
    localStorage.removeItem(key);
}

function clearStorage() {
    localStorage.clear();
}

export default {
    getItem,
    setItem,
    removeItem,
    clearStorage,
};
