/**
 * Gets the user from backend
 */

import { call, put, takeLatest, fork, select } from 'redux-saga/effects';
import { wynkMusicConfigSuccess, xstreamConfigSuccess, setAppkey, userLoadingError, wynkMusicConfigFetch, xstreamConfigFetch, userLoaded, layoutConfigSuccess } from 'core/App/actions';
import environment from 'environments/environment';
import ActionTypes from './constants';
import { getRequest, postRequestWithCustomHeader } from 'utils/request';
import { setUserData, getUserData } from 'services/user.service';
import Domain from 'models/domain.models';
import history from 'utils/history';
import { makeSelectConfigAppkey } from './selectors';
import Realm from 'models/realm.model';
import { ToastSuccess } from 'services/toast.service';
import { zionErrorHandler } from 'services/zionErrorHandler.service';
import localStorageWrapper from 'utils/localStorageWrapper';
import { getRealm, isMusicDomain, isVideoDomain } from 'utils/getRealm';


function* myProfile() {

  const { location: { search } } = history;
  const qs: any = new URLSearchParams(search);
  const realm: Realm = qs.get('realm') ? qs.get('realm').split('_')[0] : '';
  let appKey = realm ? Domain[realm] : localStorageWrapper.getItem('appKey');

  const requestURL = `${environment.ZION_URL}/user/myProfile`;

  try {
    // tslint:disable-next-line: max-line-length
    const { assignedRoles, assignedTenants, emailId, journeys, userId, userName, assignedCps } = yield call(getRequest, requestURL);
    setUserData({ assignedRoles, assignedTenants, emailId, userId, userName, assignedCps });
    yield put(userLoaded({ assignedRoles, assignedTenants, emailId, userId, userName, journeys, assignedCps }));

    const assignedTenantName = assignedTenants.map(tenant => tenant.tenantName);
    if (assignedTenantName.includes(appKey)) {
      localStorageWrapper.setItem('appKey', appKey);
      yield put(setAppkey(appKey));
    } else {
      appKey = assignedTenants[0].tenantName;
      localStorageWrapper.setItem('appKey', appKey);
      yield put(setAppkey(appKey));
    }

    if (isMusicDomain(appKey)) {
      yield put(wynkMusicConfigFetch());
    }

    if (isVideoDomain(appKey)) {
      yield put(xstreamConfigFetch());
    }
  } catch (err) {
    yield put(userLoadingError(err));
    console.log(err);
    zionErrorHandler(err);
  }
}

function* fetchWynkMusicConfig() {

  const wynkMusicConfigURL = `${environment.ZION_URL}/config`;

  try {
    const wynkMusicConfigData = yield call(getRequest, wynkMusicConfigURL);
    yield put(wynkMusicConfigSuccess(wynkMusicConfigData));
  } catch (err) {
    console.log(err);
    zionErrorHandler(err);
  }
}

function* fetchXstreamConfig() {

  const xstreamConfigURL = `${environment.ZION_URL}/config`;

  try {
    const xstreamConfigData = yield call(getRequest, xstreamConfigURL);
    yield put(xstreamConfigSuccess(xstreamConfigData));
  } catch (err) {
    console.log(err);
    zionErrorHandler(err);
  }
}

function* fetchLayoutConfig() {

  const appKey = localStorageWrapper.getItem('appKey');
  const REALM = getRealm(appKey);

  const layoutConfigURL = `${environment.PORTAL_URL}/layout/config`;

  try {
    if (REALM) {
      const layoutConfigData = yield call(getRequest, layoutConfigURL);
      yield put(layoutConfigSuccess(layoutConfigData));
    }
  } catch (err) {
    console.log(err);
    zionErrorHandler(err);
  }
}

function* fetchSlackNotification(action) {

  const { stack: errorInfo } = action.payload;
  const slackHookURL = `https://hooks.slack.com/services/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`;

  try {
    const payload = {
      blocks: [
        {
          type: 'section',
          text: {
            type: 'mrkdwn',
            text: 'Reported by *' + getUserData().emailId + '* at URL - ' + window.location.href + '. ```' + errorInfo + '```',
          },
        },
        {
          type: 'divider',
        },
      ],
    };
    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
    };

    yield call(postRequestWithCustomHeader, slackHookURL, payload, headers);
    ToastSuccess('Sent Succesfully.');
  } catch (err) {
    console.log(err);
    zionErrorHandler(err);
  }
}

function* fetchMultiArtworkSlackNotification(action) {

  const { infoTitle, infoDesc } = action.payload;
  const slackHookURL = `https://hooks.slack.com/services/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`;

  try {
    const payload = {
      blocks: [
        {
          type: 'section',
          text: {
            type: 'mrkdwn',
            text: 'Reported by *' + getUserData().emailId + '* at URL - ' + window.location.href,
          },
        },
        {
          type: 'section',
          text: {
            type: 'mrkdwn',
            text: infoTitle + '```' + infoDesc + '```',
          },
        },
        {
          type: 'divider',
        },
      ],
    };
    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
    };

    yield call(postRequestWithCustomHeader, slackHookURL, payload, headers);
  } catch (err) {
    console.log(err);
    zionErrorHandler(err);
  }
}

function* fetchProfileWatcher() {
  yield takeLatest(ActionTypes.FETCH_MY_PROFILE, myProfile);
}

function* fetchWynkMusicConfigWatcher() {
  yield takeLatest(ActionTypes.WYNK_MUSIC_CONFIG_FETCH, fetchWynkMusicConfig);
}

function* fetchXstreamConfigWatcher() {
  yield takeLatest(ActionTypes.XSTREAM_CONFIG_FETCH, fetchXstreamConfig);
}

function* fetchLayoutConfigWatcher() {
  yield takeLatest(ActionTypes.LAYOUT_CONFIG_FETCH, fetchLayoutConfig);
}

function* fetchSlackNotificationWatcher() {
  yield takeLatest(ActionTypes.SEND_SLACK_NOTIFICATION, fetchSlackNotification);
}

function* fetchMultiArtworkSlackNotificationWatcher() {
  yield takeLatest(ActionTypes.MULTI_ARTWORK_SLACK_NOTIFICATION, fetchMultiArtworkSlackNotification);
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* appSaga() {
  yield fork(fetchWynkMusicConfigWatcher);
  yield fork(fetchXstreamConfigWatcher);
  yield fork(fetchProfileWatcher);
  yield fork(fetchLayoutConfigWatcher);
  yield fork(fetchSlackNotificationWatcher);
  yield fork(fetchMultiArtworkSlackNotificationWatcher);
}
